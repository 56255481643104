import React from "react";
import PropTypes from "prop-types";
// Components
import {
  BoxText,
  BoxVideo,
  Wrapper,
  Container,
  Title,
  Text,
  Buttons,
} from "sections/inicio/MainLanding/styles";
import YoutubeVideo from "components/YoutubeVideo";
import Image from "./Image";
import Signup from "../../../components/Signup/index";
import Button from "components/Button";
import { shareWhatsapp } from "helpers/share-helpers";
import { FaWhatsapp } from "react-icons/fa";
import arrow from "sections/global/CtaSchedule/arrow.svg";

function MainLanding({ title, text }) {
  return (
    <Wrapper>
      <Container>
        <BoxVideo alignSelf="center">
          <YoutubeVideo
            size={1080 / 1920}
            src="https://www.youtube.com/embed/Mbs0hgEn4Wc"
            renderPlaceholder={() => <Image />}
          />
        </BoxVideo>
        <BoxText alignSelf="center">
          <Title>{title}</Title>
          <Text>{text}</Text>
          <Buttons>
            <Signup className="hide--mobile" />
            <Button
              as="a"
              href={shareWhatsapp("554896049333")}
              target="_blank"
              rel="noreferrer noopener"
              color="whatsapp"
              kind="solid"
            >
              <FaWhatsapp /> Conversar com consultor
            </Button>
          </Buttons>
          <small>Acesso imediato. Não é necessário cartão de crédito.</small>
        </BoxText>
      </Container>
    </Wrapper>
  );
}

MainLanding.defaultProps = {
  title: "Site, CRM e Aplicativo para imobiliária e corretor de imóveis",
  text: `Com um site, sistema e aplicativo imobiliário prático, você terá tempo 
  de sobra para focar no que realmente importa: Vender mais.`,
  onClickAction: null,
};

MainLanding.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClickAction: PropTypes.func,
};

export default MainLanding;
