import React from "react";
import { Wrapper, Box, Title, TextContent, Action, WrapButton } from "./styles";
import { FaWhatsapp } from "react-icons/fa";
import arrow from "./arrow.svg";
import Button from "components/Button";
import { shareWhatsapp } from "helpers/share-helpers";

const CtaSchedule = () => {
  return (
    <Wrapper>
      <Box>
        <TextContent>
          <Title>
            Quer saber mais? <br />
            Converse com um dos nossos consultores!
          </Title>
        </TextContent>
        <Action>
          <WrapButton>
            <Button
              as="a"
              href="https://youtu.be/6yD1KbNfACo"
              target="_blank"
              rel="noopener noreferrer"
              color="tertiary"
              kind="solid"
            >
              Ver uma demonstração
            </Button>
            <Button
              as="a"
              href={shareWhatsapp("554896049333")}
              target="_blank"
              rel="noreferrer noopener"
              color="whatsapp"
              kind="solid"
            >
              <FaWhatsapp /> Conversar com consultor
              <img src={arrow} alt="Arrow" />
            </Button>
          </WrapButton>
        </Action>
      </Box>
    </Wrapper>
  );
};

export default CtaSchedule;
